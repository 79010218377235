'use client';
import ClockIcon from '@/components/comment/icon/Clock';
import VideoGDriveIframe from '@/components/VideoGDriveIframe';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
interface ModuleCardProps {
	title: string;
	description: string;
	duration: string;
	isActive: boolean;
	onClick?: () => void;
}

const vBLogLink = '1UnMtH9UoyKBu1kCvkyVH1qwzOw_5MJuu';
const vCastLink = '1WvepjHOPfoPZPybS3IgFhK5LmPVpU9Bv';
const vwebinarLink = '1zwtHzwHeYjDjtYG9skBJ2mYOf4dbTTMZ';
const vGuideLink = '1F6N4N_9gUU__TYL7fg0FL_NG2QUP83GR';
const evEventsLink = '1fbpDW9oGOdGuVdUDfVmCJk_fKr1txHty';
const vFBGroup = '1ljmFuxBepXmiXSNlmxWaTvpIT-VYhkFW';
const vMeainMessage = '1rih_iw4WcxtjdNkcKiG2lvuwqb325CrU';
const vMessageFemale = '1pOvorp7FF0-LDWvrwj0IDbbQHjj1_1fW';
const vMessageMale = '1M9IsDgqv0_lJ3Wv0zz6X2jL8NTvzJ93E';
const vBooks = '143QsJ0fQpizSTEuk70PrzjOyy-198Hke';
const vBuuz = '1VkiQ9fpL023WpdEIZHkrtiZIZC7hfmES';
const vCollege = '1dD4VGKmKE-ajWC91EL3f4z6xhKynBx-h';
const vExpert = '1ZS1nyooyyIlTqBsxCfxp7Do-gcNN_Yr0';
const vJobs = '1QYnzVP_-vgjs3XOscjdzmvaSJmlNc7ex';
const vLance = '1siAMLxWjr6kfdr0-4YZDgNYIHCDkHcY9';
const vMarket = '1teSSMF6kjcInWKdpBxtRfzN_bBAdzJ7K';
const vNation = '15HSblIeShvwWx7PMRIXjXTb_XNyMtnMq';
const vTube = '1gtsYg0Ww01ie56op6qcYnANjwYIks4z6';

const vModulesData = [
	{
		name: 'v-guide',
		description: 'a brief video to know what is V-Guide?',
		duration: '5',
		title: 'V-Guide',
		videoLink: vGuideLink,
	},
	{
		name: 'v-blog',
		description: 'a brief video to know what is V-Blog?',
		duration: '6',
		title: 'V-Blog',
		videoLink: vBLogLink,
	},
	{
		name: 'v-webinar',
		description: 'a brief video to know what is V-Webinar?',
		duration: '7',
		title: 'V-Webinar',
		videoLink: vwebinarLink,
	},
	{
		name: 'v-cast',
		description: 'a brief video to know what is V-Cast?',
		duration: '8',
		title: 'V-Cast',
		videoLink: vCastLink,
	},
	{
		name: 'v-events',
		description: 'a brief video to know what is V-Events?',
		duration: '9',
		title: 'V-Events',
		videoLink: evEventsLink,
	},
	{
		name: 'v-fb-group',
		description: 'a brief video to know what is V-FB-Group?',
		duration: '10',
		title: 'V-FB-Group',
		videoLink: vFBGroup,
	},
	{
		name: 'v-MeainMessage',
		description: 'a brief video to know what is V-Meain Message ?',
		duration: '11',
		title: 'V- Meain Message',
		videoLink: vMeainMessage,
	},
	{
		name: 'v-message-female',
		description: 'a brief video to know what is V-Message Female ?',
		duration: '12',
		title: 'V-Message Female',
		videoLink: vMessageFemale,
	},
	{
		name: 'v-message-male',
		description: 'a brief video to know what is V-Message Male ?',
		duration: '13',
		title: 'V-Message Male',
		videoLink: vMessageMale,
	},
	{
		name: 'v-books',
		description: 'a brief video to know what is V-Books ?',
		duration: '14',
		title: 'V-Books',
		videoLink: vBooks,
	},
	{
		name: 'v-buuz',
		description: 'a brief video to know what is V-Buuz ?',
		duration: '15',
		title: 'V-Buuz',
		videoLink: vBuuz,
	},
	{
		name: 'v-college',
		description: 'a brief video to know what is V-College ?',
		duration: '16',
		title: 'V-College',
		videoLink: vCollege,
	},
	{
		name: 'v-expert',
		description: 'a brief video to know what is V-Expert ?',
		duration: '17',
		title: 'V-Expert',
		videoLink: vExpert,
	},
	{
		name: 'v-jobs',
		description: 'a brief video to know what is V-Jobs ?',
		duration: '18',
		title: 'V-Jobs',
		videoLink: vJobs,
	},
	{
		name: 'v-lance',
		description: 'a brief video to know what is V-Lance ?',
		duration: '19',
		title: 'V-Lance',
		videoLink: vLance,
	},
	{
		name: 'v-market',
		description: 'a brief video to know what is V-Market ?',
		duration: '20',
		title: 'V-Market',
		videoLink: vMarket,
	},
	{
		name: 'v-nation',
		description: 'a brief video to know what is V-Nation ?',
		duration: '21',
		title: 'V-Nation',
		videoLink: vNation,
	},
	{
		name: 'v-tube',
		description: 'a brief video to know what is V-Tube ?',
		duration: '22',
		title: 'V-Tube',
		videoLink: vTube,
	},
];

export function ModuleCard({ title, description, duration, isActive, onClick }: ModuleCardProps) {
	return (
		<div
			className={`relative m-auto h-full min-w-[100%] max-w-[100%] cursor-pointer rounded-xl border-3 bg-white p-2 transition-all duration-200 md:min-w-[250px] md:max-w-[250px] md:p-4 ${isActive ? 'border-blue-500 shadow-lg' : 'border-gray-200 hover:border-gray-300'}`}
			onClick={onClick}
		>
			{isActive && (
				<div className="absolute left-1/2 top-0 h-0 w-1 -translate-x-1/2 -translate-y-full border-b-[10px] border-l-[10px] border-r-[10px] border-t-[10px] border-b-[rgba(1,109,234,1)] border-l-transparent border-r-transparent border-t-transparent"></div>
			)}
			<h3 className="mb-3 block bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,.9)] bg-clip-text font-bold text-transparent">
				{title} intro
			</h3>
			<p className="mb-4 text-sm text-[var(--black-50,rgba(0,0,0,0.50))]">{description}</p>
			<div className="flex items-center gap-1 text-sm text-[var(--black-50,rgba(0,0,0,0.50))]">
				<ClockIcon /> <span>{duration} Mins</span>
			</div>
		</div>
	);
}

function TalkVV() {
	const t = useTranslations('header');
	const [isActive, setActive] = useState<string>('v-guide');
	const [swiper, setSwiper] = useState<any>();

	const videoId = useMemo(() => {
		switch (isActive) {
			case 'v-guide':
				return vGuideLink;
			case 'v-blog':
				return vBLogLink;
			case 'v-webinar':
				return vwebinarLink;
			case 'v-cast':
				return vCastLink;
			case 'v-events':
				return evEventsLink;
			case 'v-fb-group':
				return vFBGroup;
			case 'v-MeainMessage':
				return vMeainMessage;
			case 'v-message-female':
				return vMessageFemale;
			case 'v-message-male':
				return vMessageMale;
			case 'v-books':
				return vBooks;
			case 'v-buuz':
				return vBuuz;
			case 'v-college':
				return vCollege;
			case 'v-expert':
				return vExpert;
			case 'v-jobs':
				return vJobs;
			case 'v-lance':
				return vLance;
			case 'v-market':
				return vMarket;
			case 'v-nation':
				return vNation;
			case 'v-tube':
				return vTube;
		}
	}, [isActive]);

	return (
		<section className="relative w-full pt-0 md:py-20">
			<div className="absolute -left-20 -top-10 h-[400px] w-[400px] rounded-full bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,1)] opacity-40 blur-[70px]"></div>
			<div className="absolute bottom-0 right-0 h-[200px] w-[200px] rounded-full bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,1)] opacity-40 blur-[70px]"></div>

			<div className="container mx-auto px-4">
				<div className="md:flex-column flex flex-col items-center gap-8">
					<div className="relative space-y-6 text-center md:w-[100%]">
						<h2 className="text-2xl font-bold text-gray-800 md:text-5xl">
							<span className="mb-3 block bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,.9)] bg-clip-text font-[900] text-transparent rtl:bg-gradient-to-l">
								{t('joinVliLive')}
							</span>
						</h2>
						<p className="text-2xl text-[rgba(43,37,37,1)]">{t('watchVideos')}</p>
					</div>
					<div className="flex w-full justify-center md:w-[80%]">
						<div className="relative w-full">
							<div className="box-shadow-[0px 0px 14.21px 0px rgba(0,0,0,0.2)] relative z-10 overflow-hidden rounded-lg bg-white shadow-lg">
								<VideoGDriveIframe videoId={videoId as string} />
							</div>
						</div>
					</div>

					<div className="align-center relative flex w-[80%] -scroll-ml-0.5 gap-4 py-3 pb-5 pt-0 md:w-[80%] md:justify-center md:pb-3 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar]:w-2">
						<div className="swiper-button-next translate-x-[51px] after:hidden md:translate-x-[50px]">
							<img src="./icons/arrows-next.svg" className="w-[70px] max-w-[70px]" alt="" />
						</div>

						<Swiper
							// Register modules
							modules={[Navigation, Scrollbar]}
							// We tie our buttons to these class names:
							navigation={{
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							}}
							// (Optional) Draggable scrollbar
							scrollbar={{ draggable: true }}
							spaceBetween={30}
							slidesPerView={5.2}
							onSwiper={(swiper) => {
								setSwiper(swiper);
							}}
							breakpoints={{
								0: {
									slidesPerView: 1,
									spaceBetween: 0,
								},
								600: {
									slidesPerView: 2,
									spaceBetween: 15,
								},
								900: {
									slidesPerView: 3,
									spaceBetween: 15,
								},
								1100: {
									slidesPerView: 4,
									spaceBetween: 15,
								},
								1300: {
									slidesPerView: 4,
									spaceBetween: 15,
								},
							}}
						>
							{vModulesData.map(({ name, description, duration, title }) => (
								<SwiperSlide key={title} style={{ paddingTop: '15px' }}>
									<ModuleCard
										description={description}
										duration={duration}
										title={title}
										isActive={isActive === name}
										onClick={() => setActive(name)}
									/>
								</SwiperSlide>
							))}
						</Swiper>
						<div className="swiper-button-prev translate-x-[-50px] after:hidden md:translate-x-[-55px]">
							<img className="w-[70px] max-w-[70px]" src="./icons/arrows-prev.svg" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default TalkVV;
