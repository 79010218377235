'use client';
import { useAppSelector } from '@/lib/hooks';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useCallback } from 'react';
import logo from '../../../../public/favicon.ico';

function UnlockPotential() {
	const t = useTranslations('header');

	const auth = useAppSelector((state) => state.auth);
	const { isLogin } = auth;

	const signUpButton = useCallback(() => {
		const timer = setTimeout(() => {
			const signUpButton = document.getElementById('login/signup-button');
			if (signUpButton) {
				signUpButton.click();
			}
		}, 100);
		return () => clearTimeout(timer);
	}, []);

	return (
		<section className="relative hidden w-full py-8 md:block md:py-16">
			<div className="mx-auto w-full px-4 sm:px-6 lg:px-10">
				<div className="flex flex-col-reverse items-center gap-4 rounded-xl border border-[rgba(0,0,0,0.1)] bg-white shadow-lg md:flex-row md:gap-8 md:rounded-[15px]">
					<div className="relative w-full space-y-4 p-4 md:ms-8 md:w-[40%] md:space-y-6 md:p-6">
						<div className="h-8 w-full md:h-9">
							<Image src={logo} width={40} height={40} alt="this is logo" className="object-contain" />
						</div>
						<h2 className="text-xl font-bold leading-tight text-gray-800 sm:text-2xl lg:text-4xl">
							{['unlockYourTruePotential', 'buildNetworksOpenIncome', 'streamsMake', 'MoneyBeLeader'].map((text) => (
								<span
									key={text}
									className="mb-2 block bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,.9)] bg-clip-text text-transparent rtl:bg-gradient-to-l"
								>
									{t(text)}
								</span>
							))}
						</h2>
						{!isLogin && (
							<a
								onClick={signUpButton}
								className="inline-block cursor-pointer rounded-[50px] bg-gradient-to-r from-[#FA0101] to-[#016DEA] font-bold shadow-lg transition hover:opacity-90"
							>
								<span className="m-[2px] mx-[3px] block rounded-full bg-gradient-to-r from-[#016DEA] to-[#FA0101] px-6 py-2.5 text-sm text-white sm:px-8 sm:py-3 sm:text-[14px]">
									{t('joinVliLiveAgain')}
								</span>
							</a>
						)}
					</div>
					<div className="flex w-full justify-center md:w-[60%] md:justify-end">
						<div className="relative w-full max-w-full px-4 md:max-w-[86%] md:px-0">
							<div className="absolute -left-20 -top-20 h-60 w-60 rounded-full bg-[rgba(250,1,1,1)] opacity-30 blur-3xl sm:h-80 sm:w-80"></div>
							<div className="absolute right-[20%] top-[-50px] h-[120px] w-[120px] rounded-full bg-[rgba(1,109,234,1)] opacity-90 blur-3xl sm:h-[150px] sm:w-[150px]"></div>
							<div className="absolute bottom-[-3rem] left-[50%] h-[80px] w-[80px] translate-x-[-50%] rounded-full bg-[rgba(250,1,1,1)] opacity-50 blur-[30px] sm:h-[100px] sm:w-[100px]"></div>
							<div className="flex justify-end pe-4 shadow-none">
								<Image
									width={645.9744873046875}
									height={611.0751953125}
									src="/vh.png"
									alt="Video Thumbnail"
									className="bg-start relative z-10 rounded-lg bg-right-top"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default UnlockPotential;
