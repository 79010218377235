'use client';
import VideoGDriveIframe from '@/components/VideoGDriveIframe';
import { useAppSelector } from '@/lib/hooks';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';

function ReadyToLnleash() {
	// Replace with your actual video file's ID from Google Drive
	const videoId = '1rih_iw4WcxtjdNkcKiG2lvuwqb325CrU';
	const t = useTranslations('header');

	const auth = useAppSelector((state) => state.auth);
	const { user, isLogin } = auth;

	const signUpButton = useCallback(() => {
		const timer = setTimeout(() => {
			const signUpButton = document.getElementById('login/signup-button');
			if (signUpButton) {
				signUpButton.click();
			}
		}, 500);
		return () => clearTimeout(timer);
	}, []);

	return (
		<section className="relative w-full py-16">
			<div className="mx-auto w-full px-6 md:px-16">
				<div className="flex flex-col-reverse items-center gap-8 lg:flex-row">
					<div className="relative w-[100%] space-y-6 text-center font-poppins lg:w-[40%] lg:text-start">
						<div className="absolute -left-20 -top-10 h-[100px] w-[100px] rounded-full bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,1)] opacity-50 blur-[30px]"></div>
						<div className="absolute bottom-[-8rem] left-[50%] h-[100px] w-[100px] translate-x-[-50%] rounded-full bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,1)] opacity-50 blur-[30px]"></div>
						<h1 className="text-2xl font-bold text-gray-800 md:text-4xl">
							<span className="tl:bg-gradient-to-l mb-3 block bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,.9)] bg-clip-text text-transparent">
								{t('readyToUnleash')}
							</span>
							<span className="bg-gradient-to-r from-[rgba(1,109,234,1)] to-[rgba(250,1,1,.6)] bg-clip-text text-transparent">
								{t('unlimitedPotentials')}
							</span>
						</h1>
						<p className="text-2xl leading-[150%] leading-[24px] text-[rgba(43,37,37,1)] md:text-[20px] lg:max-w-xl">
							{t('firstOfItsKind')}
						</p>
						{!isLogin && (
							<a
								onClick={signUpButton}
								className="inline-block cursor-pointer rounded-[50px] bg-gradient-to-r from-[#FA0101] to-[#016DEA] font-bold shadow-lg transition hover:opacity-90"
							>
								<span className="m-[2px] me-[3px] ms-[3px] inline-block rounded-full bg-gradient-to-r from-[#016DEA] to-[#FA0101] px-8 py-3 text-[14px] text-white">
									{t('joinVliLive')}
								</span>
							</a>
						)}
					</div>
					<div className="flex w-[100%] justify-center lg:w-[60%] lg:justify-end">
						<div className="relative w-full max-w-[100%] md:max-w-[86%]">
							<div className="absolute -left-20 -top-20 h-80 w-80 rounded-full bg-[rgba(250,1,1,1)] opacity-30 blur-3xl"></div>
							<div className="absolute right-[20%] top-[-50px] h-[150px] w-[150px] rounded-full bg-[rgba(1,109,234,1)] opacity-90 blur-3xl"></div>
							<div className="absolute bottom-[-3rem] left-[50%] h-[100px] w-[100px] translate-x-[-50%] rounded-full bg-[rgba(250,1,1,1)] opacity-50 blur-[30px]"></div>
							<div className="box-shadow-[4px_4px_10px_0px_rgba(0,0,0,0.15)] relative z-10 inline-block h-auto w-full rounded-lg border-[10px] border-[#fff] bg-[#fff] shadow-lg">
								{/* Embed the video in place of the image */}
								<VideoGDriveIframe videoId={videoId} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ReadyToLnleash;
