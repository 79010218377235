const VideoGDriveIframe = ({ videoId }: { videoId: string }) => {
	// Construct the embed URL using the provided video ID
	const embedUrl = `https://drive.google.com/file/d/${videoId}/preview`;

	return (
		<div>
			<iframe
				className="aspect-[16/16] md:aspect-video"
				src={embedUrl}
				allow="autoplay"
				allowFullScreen
				frameBorder="0"
				style={{ borderRadius: '0.5rem ' }}
			></iframe>
		</div>
	);
};

export default VideoGDriveIframe;
