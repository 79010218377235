import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const ClockIcon = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;

	return (
		<svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.00065 15.1663C11.6825 15.1663 14.6673 12.1816 14.6673 8.49967C14.6673 4.81778 11.6825 1.83301 8.00065 1.83301C4.31875 1.83301 1.33398 4.81778 1.33398 8.49967C1.33398 12.1816 4.31875 15.1663 8.00065 15.1663Z"
				stroke="url(#paint0_linear_12844_5205)"
				stroke-width="0.8"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8 4.5V8.5L10.6667 9.83333"
				stroke="url(#paint1_linear_12844_5205)"
				stroke-width="0.8"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_12844_5205"
					x1="6.55003"
					y1="14.5098"
					x2="17.3341"
					y2="8.88991"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#016DEA" />
					<stop offset="1" stop-color="#FA0101" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_12844_5205"
					x1="9.04321"
					y1="9.57071"
					x2="11.6114"
					y2="8.90153"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#016DEA" />
					<stop offset="1" stop-color="#FA0101" />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default ClockIcon;
